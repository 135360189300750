import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/900.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'animate.css';
import 'vuetify/dist/vuetify.min.css';

import '@/assets/fonts/BreeLight.css';

import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
	theme: {
		primary: '#00aef3',
		secondary: '#1b1c20',
		accent: '#00aef3',
		error: '#ff796e',
		info: '#00aef3',
		success: '#00bc8d',
		warning: '#ff796e',
	},
});
