import Vue from 'vue';

import i18n from '../i18n';
import router from '../router';

import component from './component';

export default (new (Vue.extend(component))({
	i18n,
	router,
}));
