import Vue from 'vue';
import VueTimeago from 'vue-timeago';

Vue.use(VueTimeago, {
	name: 'VueTimeAgo',
	locale: 'en-US',
	locales: {
		'en-US': require('date-fns/locale/en')
	},
});
